import { useUI } from '@components/ui'
import { getCurrentPage } from '@framework/utils/app-util'
import Link from 'next/link'
import { FC, useEffect, useState } from 'react'
import { IExtraProps } from '../Layout/Layout'
import {
  CIN_NUMBER,
  GENERAL_REGISTERED_ADDRESS,
  REGISTERED_ADDRESS_FOOTER,
} from '@components/utils/textVariables'
import { EmptyGuid } from '@components/utils/constants'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'

declare const window: any

interface Props {
  config: []
}
const FooterMenu: FC<Props & IExtraProps> = ({ config, deviceInfo }: any) => {
  const { isMobile, isIPadorTablet } = deviceInfo
  const { user } = useUI()
  const trackMoEngageEvent = useMoEngageEvent()
  const [hydrationState, SetHydrationState] = useState<any>(false)
  let deviceCheck = ''
  if (isMobile || isIPadorTablet) {
    deviceCheck = 'Mobile'
  } else {
    deviceCheck = 'Desktop'
  }
  let currentPage = getCurrentPage()

  function footerClick(detail: any) {
    if (currentPage) {
      recordGA4Event(window, GA_EVENT.FOOTER_QUERY_CLICK, {
        device: deviceCheck,
        page_clicked_on: currentPage,
        click_detail: detail,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })

      trackMoEngageEvent(
        MO_ENGAGE_EVENT.FOOTER_CLICK,
        {
          Device: deviceCheck,
          Page_Clicked_On: currentPage,
          Click_Detail: detail,
        },
        window
      )
    }
  }

  useEffect(() => {
    SetHydrationState(true)
  }, [])

  return (
    <div className="px-2 py-2 pl-0 sm:px-0 sm:py-6 sm:pt-8 lg:pl-4">
      <div className="grid grid-cols-1 gap-4 sm:gap-8 sm:grid-cols-3 ">
        {hydrationState && config?.length && (
          <>
            {config?.map((item: any, itemNewId: number) =>
              item?.navBlocks
                ?.sort(
                  (a: { displayOrder: number }, b: { displayOrder: number }) =>
                    a.displayOrder > b.displayOrder ? 1 : -1
                )
                .map((navBlock: any, fdx: number) => (
                  <div
                    className="w-full"
                    key={`${fdx}-footer-menu-${navBlock?.boxTitle}`}
                  >
                    <h5 className="mb-2 text-sm font-semibold">
                      {navBlock?.boxTitle}
                    </h5>
                    <ul className="menu-links-ul">
                      <>
                        {navBlock?.contentBody != '' && (
                          <li
                            dangerouslySetInnerHTML={{
                              __html: navBlock?.contentBody,
                            }}
                          />
                        )}
                        {navBlock?.navItems != '' &&
                          navBlock?.navItems?.map(
                            (navItem: any, navItemIdx: number) => {
                              return (
                                <li
                                  key={`${navItemIdx}-footer-nav-item-${navItem?.caption}`}
                                  className="mb-2"
                                >
                                  <Link
                                    legacyBehavior
                                    passHref
                                    href={`/men/${navItem?.itemLink}`}
                                  >
                                    <a
                                      className="text-xs"
                                      onClick={() =>
                                        footerClick(navItem?.caption)
                                      }
                                    >
                                      {navItem?.caption}
                                    </a>
                                  </Link>
                                </li>
                              )
                            }
                          )}
                      </>
                    </ul>
                  </div>
                ))
            )}
          </>
        )}
      </div>
      <div className='border-b-2 border-gray-300 w-full mb-6 my-6 sm:mb-7 sm:my-0 md:mb-6 md:my-6' />
      <div className='flex flex-col justify-start items-start'>
        <div className='font-medium text-sm text-primary pb-4'>
          {GENERAL_REGISTERED_ADDRESS}
        </div>
        <span className='font-light text-xs text-primary max-w-[90%]'>
          {REGISTERED_ADDRESS_FOOTER}
        </span>
        <span className="pt-4">
          <b className="font-medium text-xs text-primary">CIN: </b>
          <span className="font-light text-xs text-primary">{CIN_NUMBER}</span>
        </span>
      </div>
      <div className="border-b-2 border-gray-300 w-full my-6 mb-0 sm:border-b-0 sm:my-0" />
    </div>
  )
}
export default FooterMenu
